import React from "react";
import styled from "styled-components";
import LogoLarge from "../assets/logo/ndr-logo-2020-rect.png";
import { Link } from "react-router-dom";
import { Page } from "../components";

const StyledHero = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    height: 120px;
  }
`;

const Logo = styled.div`
  background-image: url("${LogoLarge}");
  background-size: 100%;
  max-width: 360px;
  width: 100%;
  height: 85px;
  background-repeat: no-repeat;

  @media (max-width: 500px) {
    max-width: 260px;
    height: 61px;
  }
`;

const Hero = () => (
  <StyledHero>
    <Logo />
  </StyledHero>
);

const Home = () => (
  <Page>
    <Hero />
    <p>
      New Dimension Racing (NDR) is an online racing simulator event organiser
      and sanctioning body. Formed in December 2007, NDR has grown to become one
      of the most efficient, just, and experienced administrative teams in{" "}
      <a href="https://lfs.net">Live for Speed</a> (LFS). It is NDR's goal to
      provide a clean, fair, and competitive racing environment for participants
      of all abilities.
    </p>
    <p>
      Following a hiatus of NDR organised series and events due to the
      unfortunate downturn in participation in LFS, we are back. We hope to
      reinvigorate the LFS community by working with existing series organisers
      and other partners to help improve event participation in the sim as well
      as to increase exposure of what LFS has to offer.
    </p>
    <p>
      <em>
        <Link to="/interim-board">The NDR Interim Board</Link> - August 2020
      </em>
    </p>
  </Page>
);

export default Home;
