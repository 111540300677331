import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    min-height: 100%;
    background: linear-gradient(to bottom right, #ffffff, #b0b0b0);
    font-family: "Roboto", sans-serif;
  }

  body {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      background-image: radial-gradient(
        farthest-corner at 150% 150%,
        transparent 0%,
        transparent 75%,
        rgba(0, 0, 0, 0.04) 75%
      );
    }

    &.no-scroll {
      overflow: hidden;
    }
  }

  .root {
    display: flex;
    justify-content: center;
  }

  a {
    color: #404040;
  }
`;

export default GlobalStyle;
