import React from "react";
import { Page, ButtonGroup, ButtonAnchor } from "../components";

const LFSSurvey2020 = () => (
  <Page>
    <h1>2020 LFS Community Survey</h1>
    <p>
      New Dimension Racing are conducting a survey to gauge Live for Speed
      community interests in 2020. Our aim is that the results of this survey
      will directly impact the future direction of New Dimension Racing and the
      activities that it offers to the Live for Speed community.
    </p>
    <p>
      The survey has now closed and results will be posted here, and in the
      discussion thread shortly...
    </p>
    {/* <p>
      We expect the survey to take between 10 to 15 minutes to complete. Please
      consider each question carefully and provide as much information as you
      can.
    </p>
    <p>
      This survey closes at 23:59 UTC on Friday 11th September 2020. A summary
      of anonymous results will be published shortly afterwards.
    </p>
    <Protip>
      <strong>
        <i className="fas fa-star"></i> PRIZE DRAW:{" "}
      </strong>
      We are running a prize draw once submissions close. For your chance to win
      a £12 LFS voucher (or a £12 cash prize for S3 license holders), check the
      prize draw box at the end of the survey.
    </Protip>
    <p>Thank you for your participation.</p> */}
    <ButtonGroup>
      {/* <ButtonAnchor href="https://forms.gle/fsGrVNM3iT87ftNn8">
        <i className="far fa-poll-h"></i> <span>Take The Survey</span>
      </ButtonAnchor> */}
      <ButtonAnchor
        href="https://www.lfs.net/forum/thread/94593-NDR-2020-LFS-Survey-%28%2B-Prize-Draw%29"
        outline={true}
      >
        <i className="far fa-comments"></i> <span>Survey Discussion</span>
      </ButtonAnchor>
    </ButtonGroup>
  </Page>
);

export default LFSSurvey2020;
