import gt4sc2024 from "./gt4sc2024";

export { gt4sc2024 };

export default {
  name: "GT4 Sprint Cup",
  shortName: "gt4sc",
  seasons: [gt4sc2024],
  active: false,
};
