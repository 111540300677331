import styled from "styled-components";

const Protip = styled.p`
  padding: 12px;
  border: 1px solid #016fd5;
  background-color: rgba(1, 111, 213, 0.1);
  color: #016fd5;
  border-radius: 4px;
  font-size: 0.9em;
  line-height: 1.3em;

  strong {
    font-weight: 700;
  }
`;

export default Protip;
