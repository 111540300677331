import tboc from "./tboc";
import gt2c from "./gt2c";
import lls from "./lls";
import gt4sc from "./gt4sc";

export { tboc };
export { gt2c };
export { lls };
export { gt4sc };

export default [gt2c,tboc,lls,gt4sc];