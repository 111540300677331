import { css } from "styled-components";

export default css`
  &::-webkit-scrollbar {
    background-color: transparent;
    opacity: 0;

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:horizontal {
      height: 8px;
    }

    &:vertical {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
  }
  &::-webkit-resizer {
  }
`;
