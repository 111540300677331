import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { Page } from "../components";

const MemberTitle = styled.span`
  font-style: italic;
  color: #808080;

  &:before {
    content: "- ";
  }
`;

const InterimBoard = () => (
  <Page toc={true}>
    <h1>The NDR Interim Board</h1>
    <a name="introduction" href="#introduction">
      <h2>Introduction</h2>
    </a>
    <p>
      The New Dimension Racing Interim Board is in place to oversee NDR's
      current activities and to set out the future direction of the
      organisation.
    </p>
    <a name="interim-board-members" href="#interim-board-members">
      <h2>Interim Board Members</h2>
    </a>
    <ul>
      <li>
        Jonathan Palmer <MemberTitle>NDR Co-Founder</MemberTitle>
      </li>
      <li>
        Thilo Falkenberg <MemberTitle>NDR Member</MemberTitle>
      </li>
      <li>
        Jonathan Provost <MemberTitle>NDR Member</MemberTitle>
      </li>
      <li>
        Chris McElroy <MemberTitle>NDR Member</MemberTitle>
      </li>
      <li>
        Peter Butcher <MemberTitle>NDR Member</MemberTitle>
      </li>
      <li>
        Travis Mehlinger <MemberTitle>NDR Member</MemberTitle>
      </li>
    </ul>
    <a name="mission-statement" href="#mission-statement">
      <h2>Mission Statement</h2>
    </a>
    <p>
      New Dimension Racing (NDR) is an online racing simulator event organiser
      and sanctioning body. Formed in December 2007, NDR has grown to become one
      of the most efficient, just, and experienced administrative teams in{" "}
      <a href="https://lfs.net">Live for Speed</a> (LFS). It is NDR's goal to
      provide a clean, fair, and competitive racing environment for participants
      of all abilities.
    </p>
    <p>
      Following a hiatus of NDR organised series and events due to the
      unfortunate downturn in participation in LFS, we are back. We hope to
      reinvigorate the LFS community by working with existing series organisers
      and other partners to help improve event participation in the sim as well
      as to increase exposure of what LFS has to offer.
    </p>
    <p>
      <em>The NDR Interim Board - August 2020</em>
    </p>
  </Page>
);

export default InterimBoard;
