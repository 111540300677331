import logo from "./ndr-tboc-logo.png";

import { TBOC } from "../../../constants/classes";
import {
  WE11,
  SO6,
  BL1R,
  RO4,
  KY21,
  FE2,
  AS7,
} from "../../../constants/tracks";

const practiceFormat = {
  session: "practice",
  name: "Practice",
  duration: 20,
};

const qualFormat = {
  session: "qualifying",
  name: "Qualifying",
  type: "2 Shot",
  duration: 20,
};

const formatConstants = [{ ...practiceFormat }, { ...qualFormat }];

const sprintFormat = {
  session: "race",
  name: "Sprint",
  start: 0,
  sc: true,
  vsc: true,
  grid: 0,
};

const featureFormat = {
  session: "race",
  name: "Feature",
  start: 0,
  sc: true,
  vsc: true,
  grid: 1,
};

const enduFormat = {
  hours: 6,
  session: "race",
  name: "Endurance",
  start: 1,
  sc: true,
  vsc: true,
  grid: 0,
};

const description = `New Dimension Racing are proud to announce the return of the TBO Challenge, a six round touring car series using the LFS Turbo cars, the XRT, FXO, and RB4. Each round will consist of a sprint and feature race with balance of performance and success-ballast in play. To cap it all off, there will also be a post-season non-championship team endurance race. All six championship races, plus the post-season endurance will be broadcast live and in full by [simbroadcasts.tv](https://simbroadcasts.tv). Sign ups are open now and you can find out more below.`;

export default {
  hidden: false,
  shortName: "tboc2020",
  name: "TBO Challenge 2020",
  season: "2020",
  logo,
  description,
  lfsLeague: "https://www.lfs.net/leagues/60-NDR-TBO-Challemge",
  discussion: "https://www.lfs.net/forum/thread/94625",
  rulebook:
    "https://legacy.newdimensionracing.com/rules/TBOC/TBOCRegulations_Revision2_8Sep2020.pdf",
  signup: "https://www.lfs.net/forum/thread/94624",
  classes: [TBOC],
  classNotes:
    "Initial BOP is subject to change. Success ballast will come into effect after round 1 (amounts TBC). Once you have participated in an official session, you may not change your car choice for the remainder of the season.",
  schedule: [
    { session: "Free Practice", time: "14:00", duration: "20 Minutes" },
    {
      session: "Qualifying",
      time: "14:30",
      duration: "20 Minutes",
      notes: "2 Shot (2 pit exits, 2 timed laps)",
    },
    {
      session: "Sprint Race",
      time: "15:00",
      duration: "50km in laps",
      notes: "Grid set from qualifying",
    },
    {
      session: "Feature Race",
      time: "~15:45",
      duration: "85km in laps",
      notes: "Grid top 10 reversed from sprint",
    },
    {
      session: "Event End",
      time: "~16:30",
    },
  ],
  scheduleNotes:
    "For qualifying, the fast lane will be opened but pit exit will remain closed. Upon the call of the race director, the pit exit will open and the green flag will be shown. Races will be preceeded by a formation lap and standing start. Laps will be set to zero once all cars are lined up after the formation lap.",
  rounds: [
    {
      track: WE11,
      name: "Round 1",
      date: 1602424800,
      format: [
        ...formatConstants,
        { laps: 12, ...sprintFormat },
        { laps: 20, ...featureFormat },
      ],
    },
    {
      track: SO6,
      name: "Round 2",
      date: 1603634400,
      format: [
        ...formatConstants,
        { laps: 18, ...sprintFormat },
        { laps: 30, ...featureFormat },
      ],
    },
    {
      track: BL1R,
      name: "Round 3",
      date: 1604844000,
      format: [
        ...formatConstants,
        { laps: 16, ...sprintFormat },
        { laps: 26, ...featureFormat },
      ],
    },
    {
      track: RO4,
      name: "Round 4",
      date: 1605448800,
      format: [
        ...formatConstants,
        { laps: 16, ...sprintFormat },
        { laps: 27, ...featureFormat },
      ],
    },
    {
      track: KY21,
      name: "Round 5",
      date: 1606658400,
      format: [
        ...formatConstants,
        { laps: 16, ...sprintFormat },
        { laps: 27, ...featureFormat },
      ],
    },
    {
      track: FE2,
      name: "Round 6",
      date: 1607263200,
      format: [
        ...formatConstants,
        { laps: 17, ...sprintFormat },
        { laps: 26, ...featureFormat },
      ],
    },
    {
      track: AS7,
      name: "Endurance",
      date: 1608472800,
      format: [...formatConstants, { ...enduFormat }],
    },
  ],
  roundNotes:
    "The post-season endurance is a non-championship team race meaning it has no impact on the overall series standings.",
  broadcast: true,
  broadcastNotes:
    "All six championship races, plus the post-season endurance will be broadcast in full by [simbroadcasts.tv](https://simbroadcasts.tv).",
};
