// BL1
export const BL1 = {
  code: "BL1",
  name: "Blackwood GP"
};

// BL1R
export const BL1R = {
  code: "BL1R",
  name: "Blackwood GP Reversed"
};

// SO4
export const SO4 = {
  code: "SO4",
  name: "South City Long"
};

// SO5
export const SO5 = {
  code: "SO5",
  name: "South City Town Course"
};

// SO6
export const SO6 = {
  code: "SO6",
  name: "South City Chicane Route"
};

// WE1
export const WE1 = {
  code: "WE1",
  name: "Westhill National"
};
// WE2
export const WE2 = {
  code: "WE2",
  name: "Westhill International"
};

export const WE21R = {
    code: "WE2R",
    name: "Westhill International Reversed No Chicane"
};

// WE11
export const WE11 = {
  code: "WE11",
  name: "Westill National w/ Chicane"
};

// RO1
export const RO1 = {
  code: "RO1",
  name: "Rockingham ISSC"
};

// RO3
export const RO3 = {
  code: "RO3",
  name: "Rockingham Oval"
};

// RO4
export const RO4 = {
  code: "RO4",
  name: "Rockingham ISSC Long"
};

// RO7X_R71
export const R71 = {
  code: "R71",
  name: "Rockingham Intl. w/o Oval Chicane"
};

// KY2
export const KY2 = {
  code: "KY2",
  name: "Kyoto National"
};

// KY21
export const KY21 = {
  code: "KY21",
  name: "Kyoto North"
};

// KY3
export const KY3B = {
  code: "KY3",
  name: "Kyoto GP Long with Boothy Chicane"
};

// FE2
export const FE2 = {
  code: "FE2",
  name: "Fern Bay Green"
};

// FE2X_F25
export const F25 = {
  code: "F24",
  name: "Fern Bay Classic"
};

// AS6
export const AS6 = {
  code: "AS6",
  name: "Aston Grand Touring"
};

// AS7
export const AS7 = {
  code: "AS7",
  name: "Aston North"
};

// AS7R
export const AS7R = {
  code: "AS7R",
  name: "Aston North Reversed"
};

// Default export
export default {
  BL1,
  BL1R,
  SO4,
  SO5,
  SO6,
  WE1,
  WE11,
  WE2,
  WE21R,
  RO3,
  RO4,
  R71,
  KY2,
  KY21,
  KY3B,
  FE2,
  F25,
  AS6,
  AS7,
  AS7R
};
