import lls2021 from "./lls2021";

export { lls2021 };

export default {
  name: "LFSCART Light Series",
  shortName: "lls",
  seasons: [lls2021],
  active: false,
};
