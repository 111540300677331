import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import LogoLarge from "../assets/logo/ndr-logo-2020-rect.png";
import IconLarge from "../assets/logo/ndr-icon-2020-g-b.png";

import { scrollbars } from "./partials";

const HeaderNav = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

const StyledHeader = styled.header`
  height: 75px;
  line-height: 55px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;

const HeaderLogo = styled(Link)(
  ({ location }) => css`
    background-image: url("${LogoLarge}");
    background-size: 150px;
    width: 150px;
    height: 35px;
    background-repeat: no-repeat;
    align-self: center;
    visibility: ${location === "/" ? "hidden" : "visible"};
  `
);

const StyledAnchor = styled.a(
  ({ border }) => css`
    color: #404040;
    text-decoration: none;
    display: inline-block;
    height: 45px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 41px;
    padding: 0px 15px;
    border-radius: 4px;
    border: ${border ? "solid 2px #404040" : "solid 2px transparent"};
    box-sizing: border-box;
    margin-right: ${border ? "15px" : "0px"};

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    span {
      margin-left: 8px;
    }

    &:nth-last-child(2) {
      margin-right: 0px;
    }

    @media (max-width: 750px) {
      display: none;
    }
  `
);

const StyledLink = styled(Link)(
  ({ border }) => css`
    color: #404040;
    text-decoration: none;
    display: inline-block;
    height: 45px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 41px;
    padding: 0px 15px;
    border-radius: 4px;
    border: ${border ? "solid 2px #404040" : "solid 2px transparent"};
    box-sizing: border-box;
    margin-right: ${border ? "15px" : "0px"};

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    span {
      margin-left: 8px;
      &:only-child {
        margin-left: 0px;
      }
    }

    &:nth-last-child(2) {
      margin-right: 0px;
    }

    @media (max-width: 750px) {
      display: none;
    }
  `
);

const MenuIconStyles = css`
  display: none;
  margin-right: 0px;
  font-size: 1.5em;
  padding: 0px 10px;
  width: 45px;
  height: 45px;
  text-align: center;
  cursor: pointer;
  border: solid 2px transparent;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 45px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Burger = styled(StyledAnchor)`
  ${MenuIconStyles}

  @media (max-width: 750px) {
    display: block;
  }
`;

const More = styled.a`
  ${MenuIconStyles}

  display: block;

  @media (max-width: 750px) {
    display: none;
  }
`;

const Drawer = styled.div`
  z-index: 3;
  width: 300px;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(300px);
  transition: transform 0.2s ease-in-out;
  background-color: #ffffff;

  &.open {
    transform: translateX(0px);
  }

  ${scrollbars}
`;

const DrawerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 30px;
`;

const DrawerItems = styled.nav`
  border-top: solid 1px #e0e0e0;
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
`;

const DrawerItemAnchor = styled.a`
  padding: 0px 30px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  color: #404040;

  i {
    width: 20px;
  }

  span {
    margin-left: 15px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DrawerItemLink = styled(Link)`
  padding: 0px 30px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  color: #404040;

  i {
    width: 20px;
  }

  span {
    margin-left: 15px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DrawerClose = styled(StyledAnchor)`
  display: block;
  font-size: 1.5em;
  padding: 0px 10px;
  cursor: pointer;
  color: #404040;
  margin-right: 0px;
  width: 45px;
  height: 45px;
  text-align: center;
  border: solid 2px transparent;
  box-sizing: border-box;
  line-height: 45px;

  @media (max-width: 750px) {
    display: block;
  }
`;

const DrawerLogo = styled.div`
  background-image: url("${IconLarge}");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  width: 70px;
  height: 45px;
`;

const Obfuscator = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  pointer-events: none;
  opacity: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation().pathname;

  useEffect(() => {
    showMenu
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [showMenu]);

  return (
    <StyledHeader>
      <Obfuscator
        className={showMenu ? "open" : null}
        onClick={() => setShowMenu(false)}
      ></Obfuscator>
      <HeaderLogo location={location} to="/" />
      <HeaderNav>
        {/* <StyledLink border="true" to="/2020-lfs-survey">
          <i className="far fa-poll-h"></i>
          <span>2020 LFS Survey</span>
        </StyledLink> */}
        <StyledLink border="true" to="/tracker">
          <i className="far fa-stopwatch"></i>
          <span>NDR Tracker</span>
        </StyledLink>
        <StyledLink to="/interim-board">
          <span>Interim Board</span>
        </StyledLink>
        <StyledLink to="/series">
          <span>Series</span>
        </StyledLink>
        <More onClick={() => setShowMenu(true)}>
          <i className="far fa-ellipsis-v"></i>
        </More>
        <Burger onClick={() => setShowMenu(true)}>
          <i className="far fa-bars"></i>
        </Burger>
      </HeaderNav>
      <Drawer className={showMenu ? "open" : null}>
        <DrawerHeader>
          <DrawerLogo />
          <DrawerClose onClick={() => setShowMenu(false)}>
            <i className="far fa-times"></i>
          </DrawerClose>
        </DrawerHeader>
        <DrawerItems>
          <DrawerItemLink to="/" onClick={() => setShowMenu(false)}>
            <i className="far fa-home"></i>
            <span>Home</span>
          </DrawerItemLink>
          <DrawerItemLink to="/tracker" onClick={() => setShowMenu(false)}>
            <i className="far fa-stopwatch"></i>
            <span>NDR Tracker</span>
          </DrawerItemLink>
          {/* <DrawerItemLink
            to="/2020-lfs-survey"
            onClick={() => setShowMenu(false)}
          >
            <i className="far fa-poll-h"></i>
            <span>2020 LFS Survey</span>
          </DrawerItemLink> */}
          <DrawerItemLink
            to="/interim-board"
            onClick={() => setShowMenu(false)}
          >
            <i className="far fa-users"></i>
            <span>Interim Board</span>
          </DrawerItemLink>
          <DrawerItemLink to="/series" onClick={() => setShowMenu(false)}>
            <i className="far fa-flag-checkered"></i>
            <span>Series</span>
          </DrawerItemLink>
        </DrawerItems>
        <DrawerItems>
          <DrawerItemAnchor href="https://legacy.newdimensionracing.com">
            Legacy Site
          </DrawerItemAnchor>
        </DrawerItems>
        <DrawerItems>
          <DrawerItemAnchor href="https://discord.gg/6MmRVCw">
            <i className="fab fa-discord"></i>
            <span>NDR Discord Server</span>
          </DrawerItemAnchor>
          <DrawerItemAnchor href="https://twitter.com/NDR_SimRacing">
            <i className="fab fa-twitter"></i>
            <span>NDR on Twitter</span>
          </DrawerItemAnchor>
          <DrawerItemAnchor href="https://facebook.com/NewDimensionRacing/">
            <i className="fab fa-facebook-square"></i>
            <span>NDR on Facebook</span>
          </DrawerItemAnchor>
          <DrawerItemAnchor href="https://messenger.com/t/newdimensionracing">
            <i className="fab fa-facebook-messenger"></i>
            <span>Contact NDR on Messenger</span>
          </DrawerItemAnchor>
          <DrawerItemAnchor href="mailto:contact@newdimensionracing.com">
            <i className="far fa-envelope"></i>
            <span>Contact NDR via Email</span>
          </DrawerItemAnchor>
        </DrawerItems>
      </Drawer>
    </StyledHeader>
  );
};

export default Header;
