import React from "react";
import { Page, ButtonGroup, ButtonAnchor } from "../components";

const Tracker = () => (
  <Page>
    <h1>NDR Tracker</h1>
    <p>
      The NDR tracker displays live race data from any currently running NDR
      sanctioned event.
    </p>
    <ButtonGroup>
      <ButtonAnchor href="https://tracker.newdimensionracing.com">
        <i className="far far fa-external-link"></i>{" "}
        <span>Open NDR Tracker</span>
      </ButtonAnchor>
    </ButtonGroup>
    <h2>Use the NDR Tracker in your event</h2>
    <p>
      You can <a href="/contact">contact</a> us to arrange usage of the NDR
      Tracker for your event.
    </p>
    <ButtonGroup>
      <ButtonAnchor href="/contact" outline={true}>
        <i className="far far fa-external-link"></i>{" "}
        <span>Contact us for tracker use</span>
      </ButtonAnchor>
    </ButtonGroup>
  </Page>
);

export default Tracker;
