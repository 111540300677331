import React from "react";
import { Link } from "react-router-dom";
import { Page } from "../components";

const NoMatch = () => (
  <Page>
    <h1>Page not found</h1>
    <p>
      Error 404. Return <Link to="/">Home</Link>
    </p>
  </Page>
);

export default NoMatch;
