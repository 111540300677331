import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Page, ButtonGroup, ButtonAnchor } from "../components";
import ReactMarkdown from "react-markdown";

import { scrollbars } from "../components/partials";
import { seriesExists, getSeries } from "../series/utils";

const SeriesNotFound = () => (
  <>
    <h1>Series not found</h1>
    <p>
      The series you requested could not be found. Return to{" "}
      <Link to="/series">series</Link> or <Link to="/">home</Link>.
    </p>
  </>
);

const SeriesLogo = styled.figure(
  ({ logo }) => css`
    margin: 0;
    background-image: url(${logo});
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
    background-size: contain;
    background-position: center;
  `
);

const SeriesListingTable = styled.table`
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  border-spacing: 0px 5px;
  mask-image: linear-gradient(to right, black 95%, transparent 100%);

  th,
  td {
    padding-right: 15px;
  }

  th {
    border-bottom: solid 1px #b0b0b0;
  }

  ${scrollbars}
`;

const SeriesSchedule = ({ season }) => (
  <SeriesListingTable>
    <thead>
      <tr>
        <th>Session</th>
        <th>Time</th>
        <th>Duration</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
      {season.schedule.map((session, i) => (
        <tr key={i}>
          <td>{session.session}</td>
          <td>{session.time}</td>
          <td>{session.duration}</td>
          <td>{session.notes}</td>
        </tr>
      ))}
    </tbody>
  </SeriesListingTable>
);

const SeasonCarClasses = ({ season }) => {
  return season.classes.map((c) => (
    <Fragment key={c.name}>
      <h3>{c.name}</h3>
      <SeriesListingTable>
        <thead>
          <tr>
            <th>Car</th>
            <th>Code</th>
            <th>BOP</th>
          </tr>
        </thead>
        <tbody>
          {c.cars.map((car) => (
            <tr key={car.code}>
              <td>{car.name}</td>
              <td>{car.code}</td>
              <td>
                Air: {car?.restriction?.air ?? 0}%, Mass:{" "}
                {car?.restriction?.mass ?? 0}kg
              </td>
            </tr>
          ))}
        </tbody>
      </SeriesListingTable>
    </Fragment>
  ));
};

const SeasonCalendar = ({ season }) => {
  return (
    <SeriesListingTable>
      <thead>
        <tr>
          <th>Event</th>
          <th>Date</th>
          <th>Track</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        {season.rounds.map((r, i) => (
          <tr key={r.date}>
            <td>{r.name ? r.name : `Round ${i + 1}`}</td>
            <td>{new Date(r.date * 1000).toDateString()}</td>
            <td>
              {r.track.name} ({r.track.code})
            </td>
            <td>
              {r.format
                .filter((f) => f.session === "race")
                .map(
                  (ra, j, arr) =>
                    `${ra.name}: ${ra.laps ? ra.laps : ra.hours ? ra.hours : ra.minutesplus ? ra.minutesplus : ra.minutes} ${
                      ra.laps ? "laps" : ra.hours ? "hours" : ra.minutesplus ? "minutes + 1 lap" : "minutes"
                    }${j !== arr.length - 1 ? ", " : ""}`
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </SeriesListingTable>
  );
};

const SeriesListing = (props) => {
  const shortName = props.match.params.series;
  const exists = seriesExists(shortName);

  if (!exists) {
    return <SeriesNotFound />;
  }

  const s = getSeries(shortName);
  const season = s.seasons[0];

  console.log(s);

  return (
    <Page toc={true}>
      <h1>{season.name}</h1>
      <SeriesLogo logo={season.logo} />
      {season.description ? (
        <>
          <a name="introduction" href="#introduction">
            <h2>Introduction</h2>
          </a>
          <ReactMarkdown source={season.description} />
          <ButtonGroup>
            <ButtonAnchor href={season.signup} target="_blank">
              <i className="far fa-sign-in"></i> <span>Series Sign Ups</span>
            </ButtonAnchor>
            <ButtonAnchor
              href={season.discussion}
              outline={true}
              target="_blank"
            >
              <i className="far fa-comments"></i> <span>Series Discussion</span>
            </ButtonAnchor>
          </ButtonGroup>
        </>
      ) : null}
      {season.classes ? (
        <>
          <a name="classes" href="#classes">
            <h2>Car Classes</h2>
          </a>
          <SeasonCarClasses season={season} />
          <ReactMarkdown source={season.classNotes} />
        </>
      ) : null}
      {season.schedule ? (
        <>
          <a name="schedule" href="#schedule">
            <h2>Schedule and Format</h2>
          </a>
          <p>All times are in UTC.</p>
          <SeriesSchedule season={season} />
          <ReactMarkdown source={season.scheduleNotes} />
        </>
      ) : null}
      {season.rounds ? (
        <>
          <a name="calendar" href="#calendar">
            <h2>Calendar</h2>
          </a>
          <SeasonCalendar season={season} />
          <ReactMarkdown source={season.roundNotes} />
          <ButtonGroup>
            <ButtonAnchor href={season.lfsLeague} target="_blank">
              <i className="far fa-calendar"></i>{" "}
              <span>Full Series Calendar</span>
            </ButtonAnchor>
          </ButtonGroup>
        </>
      ) : null}
      {season.rulebook ? (
        <>
          <a name="rules-and-regulations" href="#rules-and-regulations">
            <h2>Rules and Regulations</h2>
          </a>
          <p>
            Download copies of the series rules and New Dimension Racing
            sporting code which are in effect in this series:
          </p>
          <ButtonGroup>
            <ButtonAnchor href={season.rulebook} target="_blank">
              <i className="far fa-book"></i>{" "}
              <span>{season.name} Rulebook</span>
            </ButtonAnchor>
            <ButtonAnchor
              href="https://legacy.newdimensionracing.com/rules/NDR/NDRSportingCode_May2020.pdf"
              target="_blank"
            >
              <i className="far fa-book"></i> <span>NDR Sporting Code</span>
            </ButtonAnchor>
          </ButtonGroup>
        </>
      ) : null}
      {season.broadcast ? (
        <>
          <a name="series-broadcast" href="#series-broadcast">
            <h2>Series Broadcast</h2>
          </a>
          <ReactMarkdown source={season.broadcastNotes} />
        </>
      ) : null}
      {/* {s.seasons.length > 1 ? <h2>Past Seasons</h2> : null} */}
    </Page>
  );
};

export default SeriesListing;
