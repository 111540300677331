import styled, { css } from "styled-components";

const ButtonAnchor = styled.a(
  ({ outline, small, color }) => css`
    padding: ${small ? "6px" : "12px"};
    height: ${small ? "30px" : "45px"};
    line-height: 18px;
    box-sizing: border-box;
    background-color: ${outline ? "transparent" : color ? color : "#016fd5"};
    border: solid 2px ${outline ? "#404040" : color ? color : "#016fd5"};
    color: ${outline ? "#404040" : "#ffffff"};
    border-radius: 4px;
    margin-right: 10px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      opacity: ${outline ? 1 : 0.9};
      background-color: ${outline
        ? "rgba(0, 0, 0, 0.05)"
        : color
        ? color
        : "#016fd5"};
    }

    span {
      margin-left: 5px;
      display: inline-block;
    }

    &:last-child {
      margin-right: 0px;
    }

    @media (max-width: 700px) {
      span {
        display: none;
      }
    }
  `
);

export default ButtonAnchor;
