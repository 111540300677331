import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  GlobalStyle,
  Wrapper,
  Header,
  Footer,
  PageContainer,
} from "./components";
import {
  Home,
  Series,
  SeriesListing,
  Contact,
  LFSSurvey2020,
  Tracker,
  InterimBoard,
  NoMatch,
} from "./pages";

import { series } from "./series";

const App = () => {
  // Series short urls
  const seriesShortUrls = series.map((s) => s.shortName);

  return (
    <Router>
      <GlobalStyle />
      <Wrapper>
        <Header />
        <PageContainer>
          <Switch>
            <Route exact path="/" component={() => <Home />} />
            <Route exact path="/series" component={() => <Series />} />
            <Route
              exact
              path="/series/:series"
              component={(props) => <SeriesListing {...props} />}
            />
            {seriesShortUrls.map((s) => (
              <Route
                key={s}
                exact
                path={`/${s}`}
                component={() => <Redirect to={`/series/${s}`} />}
              />
            ))}
            <Route exact path="/contact" component={() => <Contact />} />
            <Route
              exact
              path="/2020-lfs-survey"
              component={() => <LFSSurvey2020 />}
            />
            <Route exact path="/tracker" component={() => <Tracker />} />
            <Route
              exact
              path="/interim-board"
              component={() => <InterimBoard />}
            />
            <Route path="*" component={() => <NoMatch />} />
          </Switch>
        </PageContainer>
        <Footer />
      </Wrapper>
    </Router>
  );
};

export default App;
