import logo from "./ndr-gt4sc-logo.png";

import { GT4 } from "../../../constants/classes";
import { WE1, BL1, RO1 } from "../../../constants/tracks";

const practiceFormat = {
  session: "practice",
  name: "Practice",
  duration: 20,
};

const qualFormat = {
  session: "qualifying",
  name: "Qualifying",
  type: "Open Track",
  duration: 20,
};

const formatConstants = [{ ...practiceFormat }, { ...qualFormat }];

const sprintFormat = {
  session: "race",
  name: "Race 1",
  start: 0,
  sc: true,
  vsc: true,
  grid: 0,
};

const featureFormat = {
  session: "race",
  name: "Race 2",
  start: 0,
  sc: true,
  vsc: true,
  grid: 1,
};

const description = `New Dimension Racing are proud to announce the GT4 Sprint Cup, a three-round series using the N.400S GT4 mod by Aleksander_124rus. Each round will consist of two races, each 20 minutes + 1 lap in duration.  Sign ups are open now and you can find out more below.`;

export default {
  hidden: false,
  shortName: "gt4sc2024",
  name: "GT4 Sprint Cup 2024",
  season: "2024",
  logo,
  description,
  lfsLeague: "https://www.lfs.net/forum/535-GT4-Sprint-Cup",
  discussion: "https://www.lfs.net/forum/thread/106507",
  rulebook: "https://www.lfs.net/forum/thread/106508",
  signup: "https://www.lfs.net/forum/thread/106511",
  classes: [GT4],
  classNotes: "",
  schedule: [
    { session: "Free Practice", time: "15:00", duration: "20 Minutes" },
    {
      session: "Qualifying",
      time: "15:30",
      duration: "20 Minutes",
      notes:
        "Open Course - Semi-Hardcore mode (no telepit or spectate and rejoin from outside the pit lane",
    },
    {
      session: "Race 1",
      time: "16:00",
      duration: "20 minutes + 1 lap",
      notes: "Grid set from qualifying",
    },
    {
      session: "Race 2",
      time: "~16:50",
      duration: "20 minutes + 1 lap",
      notes: "Grid top 10 reversed from Race 1 result",
    },
    {
      session: "Event End",
      time: "~17:30",
    },
  ],
  scheduleNotes:
    "For qualifying, the fast lane will be opened but pit exit will remain closed. Upon the call of the race director, the pit exit will open and the green flag will be shown. Races will be preceeded by a formation lap and double-file rolling start. Laps will be set to zero once all cars are lined up after the formation lap.",
  rounds: [
    {
      track: WE1,
      name: "Round 1",
      date: 1705244400,
      format: [
        ...formatConstants,
        { minutesplus: 20, ...sprintFormat },
        { minutesplus: 20, ...featureFormat },
      ],
    },
    {
      track: BL1,
      name: "Round 2",
      date: 1705849200,
      format: [
        ...formatConstants,
        { minutesplus: 20, ...sprintFormat },
        { minutesplus: 20, ...featureFormat },
      ],
    },
    {
      track: RO1,
      name: "Round 3",
      date: 1706454000,
      format: [
        ...formatConstants,
        { minutesplus: 20, ...sprintFormat },
        { minutesplus: 20, ...featureFormat },
      ],
    },
  ],
  roundNotes:
    "All rounds will see the Race 2 Grid set by the Race 1 finish, except the top 10 will be reversed.",
  broadcast: true,
  broadcastNotes:
    "All three championship races will be broadcast in full by [simbroadcasts.tv](https://simbroadcasts.tv).",
};
