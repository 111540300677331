import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const Content = styled.div`
  width: 750px;
  padding: 0px 15px;
  line-height: 1.5em;
  color: #404040;
  text-align: justify;
  font-weight: 400;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 2em;
    font-weight: 300;
    padding-bottom: 30px;
    border-bottom: solid 1px #c0c0c0;
  }

  a[name] {
    text-decoration: none;
    display: block;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    margin-top: 30px;
    font-weight: 400;

    &::before {
      position: absolute;
      left: -1.5em;
      padding-right: 1.5em;
      line-height: 1.6em;
      font-size: 0.7em;
      font-family: "Font Awesome 5 Pro";
      content: "\uf0c1";
      color: #606060;
      opacity: 0;
      user-select: none;
      cursor: normal;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  p,
  li {
    font-weight: 400;
  }

  @media (max-width: 810px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const StyledTOC = styled.div(
  ({ mobile }) => css`
    display: ${mobile ? "none" : "block"};
    position: ${mobile ? "fixed" : "sticky"};
    top: ${mobile ? "0px" : "25px"};
    width: 215px;
    padding: 0px 15px;
    align-self: flex-start;
    margin-right: 15px;
    color: #606060;

    h3 {
      margin-top: 0px;
      font-weight: 300;
    }

    @media (max-width: 1070px) {
      display: ${mobile ? "block" : "none"};
    }
  `
);

const TOCList = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
`;

const TOCListItem = styled(Link)(
  ({ nodename }) => css`
    text-decoration: none;
    display: block;
    padding-left: ${parseInt(nodename.split("").pop() - 2) * 10}px;
    font-weight: 400;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  `
);

const TOC = ({ headings, anchors, mobile }) => {
  const headingsArray = Array.from(headings);
  const anchorsArray = Array.from(anchors);
  const headingOffsets = headingsArray.map((el) =>
    Math.abs(
      document.body.getBoundingClientRect().top -
        el.getBoundingClientRect().top +
        25
    )
  );

  const tocListLinkTo = (i) =>
    headingsArray.length === anchorsArray.length ? anchorsArray[i]?.hash : "#";

  const scrollTo = (offset) => {
    window.scrollTo(0, offset);
  };

  return (
    <StyledTOC mobile={mobile}>
      <h3>Contents</h3>
      <TOCList>
        {headingsArray.map((el, i) => (
          <TOCListItem
            to={tocListLinkTo(i)}
            key={i}
            nodename={el.nodeName}
            onClick={() => scrollTo(headingOffsets[i])}
          >
            {el.innerHTML}
          </TOCListItem>
        ))}
      </TOCList>
    </StyledTOC>
  );
};

const Page = ({ toc, children }) => {
  const [headings, setHeadings] = useState([]);
  const [anchors, setAnchors] = useState([]);
  const pageRef = useRef(null);

  useEffect(() => {
    const headingQuery = pageRef.current.querySelectorAll(
      "[name] h2, [name] h3, [name] h4, [name] h5, [name] h6"
    );
    const anchorQuery = pageRef.current.querySelectorAll("[name]");
    setHeadings(headingQuery);
    setAnchors(anchorQuery);
  }, []);

  return (
    <>
      {toc ? <TOC headings={headings} anchors={anchors} /> : null}
      <Content ref={pageRef}>{children}</Content>
    </>
  );
};

export default Page;
