import {
  XFG,
  XRG,
  XRT,
  XRC,
  RB4,
  FXO,
  FXC,
  FXR,
  FX9,
  XRR,
  XR9,
  FZR,
  FZ9,
  FOX,
  N4SGT4,
} from "./cars";

// GTi Class
export const GTI = {
  code: "GTI",
  name: "GTi",
  cars: [XFG, XRG],
};

// Turbo Class
export const TBO = {
  code: "TBO",
  name: "Turbo",
  cars: [XRT, RB4, FXO],
};

// TBOC Turbo Class
export const TBOC = {
  code: "TBOC",
  name: "TBOC Turbo",
  cars: [XRC, RB4, FXC],
};

// GTR Class
export const GTR = {
  code: "GTR",
  name: "Grand Touring",
  cars: [FXR, XRR, FZR],
};

// GT2C GT2 Class
export const GT9 = {
  code: "GT9",
  name: "GTR GT2",
  cars: [FX9, XR9, FZ9],
};

// LFSCart Light Series
export const LLS = {
  code: "LLS",
  name: "LLS",
  cars: [FOX],
};

// GT4 Sprint Cup
export const GT4 = {
  code: "GT4",
  name: "GT4",
  cars: [N4SGT4],
};

// Default export
export default { GTI, TBO, TBOC, GTR, GT9, LLS, GT4 };
