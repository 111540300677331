import React from "react";
import styled from "styled-components";
import { Page } from "../components";

const ContactMethod = styled.a`
  text-decoration: none;
  font-size: 2em;
  margin-right: 15px;

  &:last-child {
    margin-right: 0px;
  }
`;

const Contact = () => (
  <Page>
    <h1>Contact Us</h1>
    <p>
      You can get in touch with us on our{" "}
      <a href="https://discord.gg/6MmRVCw">Discord server</a>, via{" "}
      <a href="https://www.facebook.com/messages/t/NewDimensionRacing">
        Facebook Messenger
      </a>
      , or via <a href="mailto:contact@newdimensionracing.com">Email</a>. When
      contacting us, please allow up to 48 hours for a response before trying to
      reach us again.
    </p>
    <ContactMethod
      className="fab fa-discord"
      href="https://discord.gg/6MmRVCw"
    />
    <ContactMethod
      className="fab fa-facebook-messenger"
      href="https://www.facebook.com/messages/t/NewDimensionRacing"
    />
    <ContactMethod
      className="fa fa-envelope"
      href="mailto:contact@newdimensionracing.com"
    />
  </Page>
);

export default Contact;
