import series from "../series";

// Returns true if a series shortName exists
export const seriesExists = (shortName) =>
  series.map((s) => s.shortName).includes(shortName);

// Returns a series by its shortName
export const getSeries = (shortName) =>
  series.find((s) => shortName === s.shortName);

// Returns all series with the active flag set
export const getActiveSeries = () => series.filter((s) => s.active);

// Returns all series with active set as false
export const getInactiveSeries = () => series.filter((s) => s.active === false);

export default { getActiveSeries, getInactiveSeries };
