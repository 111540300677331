import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Page } from "../components";

import { ButtonAnchor } from "../components";

import { utils } from "../series";
import { scrollbars } from "../components/partials";

const SeriesListingTable = styled.table`
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  border-spacing: 0px 5px;
  mask-image: linear-gradient(to right, black 95%, transparent 100%);

  th,
  td {
    padding: 10px 15px 10px 0px;
  }

  th {
    border-bottom: solid 1px #b0b0b0;
    padding: 0px;
    padding-right: 15px;
  }

  ${scrollbars}
`;

const Series = () => {
  const activeSeries = utils.getActiveSeries();
  const inactiveSeries = utils.getInactiveSeries();
  console.log(activeSeries);
  console.log(inactiveSeries);

  return (
    <Page>
      <h1>NDR Series</h1>
      {activeSeries.length >= 1 ? (
        <>
          <a name="active-series" href="#active-series">
            <h2>Active Series</h2>
          </a>
          <SeriesListingTable>
            <thead>
              <tr>
                <th>Series</th>
                <th>Season</th>
                <th>Next Event</th>
                <th>Time and Date</th>
                <th>Signups</th>
                <th>Info</th>
              </tr>
            </thead>
            <tbody>
              {activeSeries.map((s) => {
                const remaining = s.seasons[0].rounds.filter(
                  (r) => Date.now() < r.date * 1000
                );
                return (
                  <tr key={s.name}>
                    <td>
                      <Link to={`/series/${s.shortName}`}>{s.name}</Link>
                    </td>
                    <td>{s.seasons[0].season}</td>
                    <td>{remaining[0].name}</td>
                    <td>{new Date(remaining[0].date * 1000).toDateString()}</td>
                    <td>
                      <ButtonAnchor href={s.seasons[0].signup}>
                        <i className="far fa-external-link-alt"></i>{" "}
                        <span>Open</span>
                      </ButtonAnchor>
                    </td>
                    <td>
                      <ButtonAnchor href={`/series/${s.shortName}`}>
                        <i className="far fa-info-circle"></i>
                        <span>Series Info</span>
                      </ButtonAnchor>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </SeriesListingTable>
        </>
      ) : (
        <em>There are currently no active NDR series.</em>
      )}
      {inactiveSeries.length >= 1 ? (
        <>
          <a name="inactive-series" href="#inactive-series">
            <h2>Inactive Series</h2>
          </a>
          <SeriesListingTable>
            <thead>
              <tr>
                <th>Series</th>
                <th>Season</th>
                <th>Info</th>
              </tr>
            </thead>
            <tbody>
              {inactiveSeries.map((p) => {
                return (
                  <tr key={p.name}>
                    <td>
                      <Link to={`/series/${p.shortName}`}>{p.name}</Link>
                    </td>
                    <td>{p.seasons[0].season}</td>
                    <td>
                      <ButtonAnchor href={`/series/${p.shortName}`}>
                        <i className="far fa-info-circle"></i>
                        <span>Series Info</span>
                      </ButtonAnchor>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </SeriesListingTable>
        </>
      ) : (
        <em>There are currently no inactive NDR series.</em>
      )}
    </Page>
  );
};

export default Series;
