import tboc2020 from "./tboc2020";

export { tboc2020 };

export default {
  name: "TBO Challenge",
  shortName: "tboc",
  seasons: [tboc2020],
  active: false,
};
