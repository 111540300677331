// XFG
export const XFG = {
  code: "XFG",
  name: "XF GTI",
};

// XRG
export const XRG = {
  code: "XRG",
  name: "XR GT",
};

// XRT
export const XRT = {
  code: "XRT",
  name: "XR GT Turbo",
};

// XRC
export const XRC = {
  ...XRT,
  restriction: {
    air: 2,
    mass: 0,
  },
};

// FXO
export const FXO = {
  code: "FXO",
  name: "FXO Turbo",
};

// FXC
export const FXC = {
  ...FXO,
  restriction: {
    air: 6,
    mass: 0,
  },
};

// RB4
export const RB4 = {
  code: "RB4",
  name: "RB4 GT",
};

// FXR
export const FXR = {
  code: "FXR",
  name: "FXO GTR",
};

// FX9
export const FX9 = {
  ...FXR,
  restriction: {
    air: 24,
    mass: 0,
  },
};

// FXR
export const XRR = {
  code: "XRR",
  name: "XR GTR",
};

// XR9
export const XR9 = {
  ...XRR,
  restriction: {
    air: 25,
    mass: 0,
  },
};

// FZR
export const FZR = {
  code: "FZR",
  name: "FZ50 GTR",
};

// FZ9
export const FZ9 = {
  ...FZR,
  restriction: {
    air: 21,
    mass: 0,
  },
};

// FOX
export const FOX = {
  code: "FOX",
  name: "Formula XR",
};

// N.400S GT4 mod
export const N4SGT4 = {
    code: "N4SGT4",
    name: "N.400S GT4",
};

// Default export
export default {
  XFG,
  XRG,
  XRT,
  XRC,
  FXO,
  FXC,
  RB4,
  FXR,
  FX9,
  XRR,
  XR9,
  FZR,
  FZ9,
  FOX,
  N4SGT4,
};
