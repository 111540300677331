import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LogoLarge from "../assets/logo/ndr-icon-2020-g.png";

const FooterNav = styled.nav`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;

    a:nth-child(2) {
      height: 40px;
      line-height: 40px;
    }
  }
`;

const FooterLogo = styled(Link)`
  background-image: url("${LogoLarge}");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  width: 70px;
  height: 25px;

  @media (max-width: 500px) {
    align-self: center;
    margin: 0;
  }
`;

const StyledFooter = styled.footer`
  height: 80px;
  line-height: 25px;
  padding: 40px 15px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
    line-height: 15px;
  }
`;

const Copyright = styled(Link)`
  color: #404040;
  text-decoration: none;
  display: inline-block;
  padding: 0px 15px;
  font-size: 0.8em;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

const SocialIcon = styled.a`
  color: #404040;
  text-decoration: none;
  display: inline-block;
  margin: 0px 8px;
  line-height: 25px;
  &:last-child {
    margin-right: 0px;
  }
`;

const SocialIconLink = styled(Link)`
  color: #404040;
  text-decoration: none;
  display: inline-block;
  margin: 0px 8px;
  line-height: 25px;
  &:last-child {
    margin-right: 0px;
  }
`;

const Footer = () => (
  <StyledFooter>
    <FooterLeft>
      <FooterLogo to="/" />
      <Copyright to="/">
        &copy; New Dimension Racing {new Date().getFullYear()}
      </Copyright>
    </FooterLeft>
    <FooterNav>
      <SocialIcon
        className="fab fa-discord"
        href="https://discord.gg/6MmRVCw"
      ></SocialIcon>
      <SocialIcon
        className="fab fa-twitter"
        href="https://twitter.com/NDR_SimRacing"
      ></SocialIcon>
      <SocialIcon
        className="fab fa-facebook-square"
        href="https://facebook.com/NewDimensionRacing/"
      ></SocialIcon>
      <SocialIcon
        className="fab fa-facebook-messenger"
        href="https://messenger.com/t/newdimensionracing"
      ></SocialIcon>
      <SocialIconLink
        className="far fa-envelope"
        to="/contact"
      ></SocialIconLink>
    </FooterNav>
  </StyledFooter>
);

export default Footer;
