import logo from "./ndr-lls-logo.png";

import { LLS } from "../../../constants/classes";
import { BL1, KY21, RO3, SO5, WE1 } from "../../../constants/tracks";

const practiceFormat = {
  session: "practice",
  name: "Practice",
  duration: 20,
};

const qualFormat = {
  session: "qualifying",
  name: "Qualifying",
  type: "Open Track",
  duration: 20,
};

const formatConstants = [{ ...practiceFormat }, { ...qualFormat }];

const sprintFormat = {
  session: "race",
  name: "Race 1",
  start: 0,
  sc: true,
  vsc: true,
  grid: 0,
};

const featureFormat = {
  session: "race",
  name: "Race 2",
  start: 0,
  sc: true,
  vsc: true,
  grid: 1,
};

// const enduFormat = {
//   hours: 6,
//   session: "race",
//   name: "Endurance",
//   start: 1,
//   sc: true,
//   vsc: true,
//   grid: 0
// };

const description = `New Dimension Racing are proud to announce the return of the LFSCART Light Series, a five round open wheel series using the Formula XR. Each round will consist of two races totalling 240 kilometres of racing.  Sign ups are open now and you can find out more below.`;

export default {
  hidden: false,
  shortName: "lls2021",
  name: "LFSCART Light Series 2021",
  season: "2021",
  logo,
  description,
  lfsLeague: "https://www.lfs.net/forum/265-LFSCART-Light",
  discussion: "https://www.lfs.net/forum/thread/82922",
  rulebook:
    "https://legacy.newdimensionracing.com/rules/LFSCART%20Light/LLSRulebook.pdf",
  signup: "https://www.lfs.net/forum/thread/95439",
  classes: [LLS],
  classNotes:
    "Drivers may only use the following tyre compunds on road courses: R1, R2, R3.  Drivers may only use R1 tyres on Ovals.",
  schedule: [
    { session: "Free Practice", time: "13:50", duration: "20 Minutes" },
    {
      session: "Qualifying",
      time: "14:20",
      duration: "20 Minutes",
      notes: "Open Course",
    },
    {
      session: "Race 1",
      time: "15:00",
      duration: "120km in laps",
      notes: "Grid set from qualifying",
    },
    {
      session: "Race 2",
      time: "~16:00",
      duration: "120km in laps",
      notes: "Grid top 8-12 reversed from Race 1",
    },
    {
      session: "Event End",
      time: "~16:50",
    },
  ],
  scheduleNotes:
    "For qualifying, the fast lane will be opened but pit exit will remain closed. Upon the call of the race director, the pit exit will open and the green flag will be shown. Races will be preceeded by a formation lap and standing start. Laps will be set to zero once all cars are lined up after the formation lap.",
  rounds: [
    {
      track: BL1,
      name: "Round 1",
      date: 1629035400,
      format: [
        ...formatConstants,
        { laps: 37, ...sprintFormat },
        { laps: 37, ...featureFormat },
      ],
    },
    {
      track: KY21,
      name: "Round 2",
      date: 1630245000,
      format: [
        ...formatConstants,
        { laps: 39, ...sprintFormat },
        { laps: 39, ...featureFormat },
      ],
    },
    {
      track: RO3,
      name: "Round 3",
      date: 1631454600,
      format: [...formatConstants, { laps: 102, ...sprintFormat }],
    },
    {
      track: SO5,
      name: "Round 4",
      date: 1632664200,
      format: [
        ...formatConstants,
        { laps: 39, ...sprintFormat },
        { laps: 39, ...featureFormat },
      ],
    },
    {
      track: WE1,
      name: "Round 5",
      date: 1633873800,
      format: [
        ...formatConstants,
        { laps: 28, ...sprintFormat },
        { laps: 28, ...featureFormat },
      ],
    },
  ],
  roundNotes:
    "All road course rounds will see the Race 2 Grid set by the Race 1 finish, except the top 8-12 will be reversed based on the Race 1 winner's finishing time.",
  broadcast: true,
  broadcastNotes:
    "All five championship races will be broadcast in full by [simbroadcasts.tv](https://simbroadcasts.tv).",
};
