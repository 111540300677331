import logo from "./ndr-gt2c-logo.png";

import { GT9 } from "../../../constants/classes";
import { KY3B, R71, AS7R, WE21R } from "../../../constants/tracks";

const preQualifyingFormat = {
  session: "prequalifying",
  name: "Pre-Qualifying",
  duration: 2880
};

const practiceFormat = {
  session: "practice",
  name: "Practice",
  duration: 20
};

const qualFormat = {
  session: "qualifying",
  name: "Qualifying",
  type: "Open",
  duration: 20
};

const formatConstants = [
  { ...preQualifyingFormat },
  { ...practiceFormat },
  { ...qualFormat }
];

const raceFormat = {
  session: "race",
  name: "Race",
  start: 0,
  sc: true,
  vsc: true,
  grid: 1
};

const description = `New Dimension Racing announce the return of the GT2 Challenge, a four round GT series using the LFS GT cars, the FZR, XRR, and FXR. Each round will consist of a 230 kilometer race. All four championship races will be broadcast live and in full by [simbroadcasts.tv](https://simbroadcasts.tv). Sign ups are open now and you can find out more below.`;

export default {
  hidden: false,
  shortName: "gt2c2024",
  name: "GT2 Challenge 2024",
  season: "2024",
  logo,
  description,
  lfsLeague: "https://www.lfs.net/leagues/544-GT2-Challenge",
  discussion: "https://www.lfs.net/forum/thread/94876",
  rulebook:
    "https://legacy.newdimensionracing.com/rules/GT2C/GT2CRulebook_Rev2.pdf",
  signup: "https://www.lfs.net/forum/thread/106831",
  classes: [GT9],
  classNotes:
    "Once you have participated in an official session, you may not change your car choice for the remainder of the season.",
  schedule: [
    {
      session: "Pre-Qualifying",
      time: "13:00",
      duration: "48 hours",
      notes: "Required for all drivers.  Starts Friday prior to round."
    },
    {
      session: "Free Practice",
      time: "15:00",
      duration: "20 Minutes"
    },
    {
      session: "Qualifying",
      time: "15:30",
      duration: "20 Minutes",
      notes:
        "Open Session, no limit on laps.  Held in the semi-hardcore mode - drivers must be inside the pit lane to telepit and rejoin."
    },
    {
      session: "Race",
      time: "16:00",
      duration: "230km in laps",
      notes: "Grid set from qualifying"
    },
    {
      session: "Event End",
      time: "~18:00"
    }
  ],
  scheduleNotes:
    "From Round 2, the Top 10 in the driver's championship do not have to do Pre-Qualifying. Races will be preceeded by a formation lap and double-file rolling start. The formation lap is not counted as part of the race distance.  All races have a 1 hour 45 minute time limit.",
  rounds: [
    {
      track: AS7R,
      name: "Round 1",
      date: 1707058800,
      format: [...formatConstants, { laps: 45, ...raceFormat }]
    },
    {
      track: R71,
      name: "Round 2",
      date: 1708268400,
      format: [...formatConstants, { laps: 61, ...raceFormat }]
    },
    {
      track: KY3B,
      name: "Round 3",
      date: 1709478000,
      format: [...formatConstants, { laps: 32, ...raceFormat }]
    },
    {
      track: WE21R,
      name: "Round 4",
      date: 1710687600,
      format: [...formatConstants, { laps: 40, ...raceFormat }]
    },
  ],
  roundNotes:
    "",
  broadcast: true,
  broadcastNotes:
    "All four championship races will be broadcast in full by [simbroadcasts.tv](https://simbroadcasts.tv)."
};
