import styled from "styled-components";

const ButtonGroup = styled.div`
  margin: 30px 0px;
  display: flex;
  font-weight: 400;
  flex-flow: row wrap;
`;

export default ButtonGroup;
