
import gt2c2024 from "./gt2c2024";

export { gt2c2024 };

export default {
  name: "GT2 Challenge",
  shortName: "gt2c",
  seasons: [gt2c2024],
  active: true,
};
